import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import emailjs from "emailjs-com";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import myImg from "../../Assets/IMG_9883.jpg";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name && formData.email && formData.message) {
      emailjs
        .send(
          "service_v7upjto", // Remplacez par votre ID de service
          "template_cpvpmmn", // Remplacez par votre ID de template
          {
            from_name: formData.name,
            from_email: formData.email,
            message: formData.message,
          },
          "FAPYgCzCX6t9y-bXC" // Remplacez par votre clé publique
        )
        .then(
          (result) => {
            console.log(result.text);
            setSuccess(true);
            setError(false);
            setFormData({ name: "", email: "", message: "" });
          },
          (error) => {
            console.error(error.text);
            setSuccess(false);
            setError(true);
          }
        );
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <div style={styles.container}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    
      <Container fluid>
        <h1
          style={{
            fontSize: "2rem", // Taille par défaut pour desktop
            textAlign: "center",
            marginBottom: "20px",
            color: "#6c63ff",
            marginTop: "10px",
          }}
          className="responsive-title"
        >
          Contactez-moi
        </h1>

        <br />
        <Row className="align-items-center">
          {/* Message à gauche */}
          <Col md={6} xs={12} className="text-center mb-4">
            <img
              src={myImg}
              className="img-fluid avatar-circle w-50 mx-auto d-block"
              alt="avatar"
            />
            <br />
            <p>
              Ne hésitez pas à <span className="purple">contacter</span> sur
            </p>
            <p>
              2 rue Chemin Vert,
              <br />
              59300, Aulnoy-Lez-Valenciennes
              <br />
              +33 07 75 84 09 62
              <br />
              <a
                href="mailto:chehem21@gmail.com"
                style={{ color: "white", textDecoration: "none" }}
              >
                chehem21@gmail.com
              </a>
            </p>
            <ul className="home-about-social-links d-flex justify-content-center">
              <li className="social-icons mx-2">
                <a
                  href="https://github.com/maha22i"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons mx-2">
                <a
                  href="https://twitter.com/home?lang=fr"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons mx-2">
                <a
                  href="https://www.linkedin.com/in/mohamed-chehem-563057231/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons mx-2">
                <a
                  href="https://www.instagram.com/chehemmahami"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>

          {/* Formulaire à droite */}
          <Col md={6} xs={12}>
            {success && (
              <Alert variant="success">Message envoyé avec succès !</Alert>
            )}
            {error && (
              <Alert variant="danger">
                Une erreur s'est produite. Veuillez réessayer.
              </Alert>
            )}

            <Form onSubmit={handleSubmit} style={styles.form}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label style={styles.label}>
                  Votre Nom et Prénom
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez votre nom"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={styles.input}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label style={styles.label}>Votre adresse mail</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Entrez votre email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={styles.input}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label style={styles.label}>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Entrez votre message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  style={styles.textarea}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" style={styles.button}>
                Envoyer
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    padding: "50px 20px",
    backgroundColor: "#121212",
    color: "#ffffff",
    minHeight: "100vh",
  },
  form: {
    backgroundColor: "#1e1e1e",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0 5px 15px rgba(108, 99, 255, 0.5)",
  },
  label: {
    color: "#ffffff",
  },
  input: {
    backgroundColor: "#2a2a2a",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
  },
  textarea: {
    backgroundColor: "#2a2a2a",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
  },
  button: {
    backgroundColor: "#6c63ff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    fontSize: "16px",
    color: "#ffffff",
    width: "100%",
    marginTop: "10px",
    transition: "background-color 0.3s ease",
  },
};

export default Contact;
