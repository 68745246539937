import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  SiFirebase,
  SiUnity,
  SiCsharp,
  SiHtml5, SiCss3, SiJavascript
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
  <Col xs={4} md={2} className="tech-icons">
    <DiNodejs />
    <div className="text-center small mt-2">Node.js</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <DiReact />
    <div className="text-center small mt-2">React.js</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <DiMongodb />
    <div className="text-center small mt-2">MongoDB</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <SiCsharp />
    <div className="text-center small mt-2">C#</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <DiGit />
    <div className="text-center small mt-2">Git</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <SiFirebase />
    <div className="text-center small mt-2">Firebase</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <DiPython />
    <div className="text-center small mt-2">Python</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <SiUnity />
    <div className="text-center small mt-2">Unity</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <SiHtml5 />
    <p className="text-center small mt-2">HTML5</p>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <SiCss3 />
    <div className="text-center small mt-2">CSS3</div>
  </Col>
  <Col xs={4} md={2} className="tech-icons">
    <SiJavascript />
    <div className="text-center small mt-2">JavaScript</div>
  </Col>
</Row>

  );
  
}

export default Techstack;
