import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/ET_DEV_OPENCV-1.jpg";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import aura from "../../Assets/Projects/aura.png";
import carte from "../../Assets/Projects/carte.png";
import meteo from "../../Assets/Projects/méteo.png";
import ifat from "../../Assets/Projects/ifat.png";

function Projects() {
  const [showMore, setShowMore] = useState({});

  const toggleDescription = (project) => {
    setShowMore((prev) => ({
      ...prev,
      [project]: !prev[project],
    }));
  };

  const projects = [
    {
      title: "BOOKSITE",
      imgPath: chatify,
      ghLink: "https://github.com/maha22i/booksite",
      demoLink: "https://booksite-z9ww.onrender.com/",
      descriptionShort: "Application web réalisée avec React.js et l'API Google Books pour le back-end...",
      descriptionLong: `Application web réalisée avec React.js et l'API Google Books pour le back-end. BookApp permet aux utilisateurs de rechercher des livres, de les ajouter à leurs favoris et d'accéder à des informations détaillées comme des résumés et d'autres métadonnées. Avec une interface moderne et intuitive, cette application offre une expérience fluide pour découvrir de nouveaux livres, organiser sa collection personnelle et explorer des recommandations littéraires. Technologies utilisées : React.js, Google Books API.`,
    },
    {
      title: "Aurashop",
      imgPath: aura,
      ghLink: "https://github.com/maha22i/AuraShop",
      demoLink: "https://aurashop.onrender.com/",
      descriptionShort: "AuraShop est une plateforme de shopping en ligne basée à Djibouti...",
      descriptionLong: `AuraShop est une plateforme de shopping en ligne basée à Djibouti, conçue pour offrir une expérience d'achat unique et conviviale. Le site propose une large gamme de produits pour hommes, femmes et enfants, répondant aux besoins vestimentaires et aux tendances actuelles.`,
    },
    {
      title: "DigiCarte",
      imgPath: carte,
      ghLink: "https://github.com/maha22i/DigiCarte",
      demoLink: "https://digicarte.onrender.com/",
      descriptionShort: "DigiCarte est une plateforme innovante dédiée à la création de cartes de visite...",
      descriptionLong: `DigiCarte est une plateforme innovante dédiée à la création et à la gestion de cartes de visite électroniques. Conçue pour simplifier les échanges professionnels, DigiCarte offre aux utilisateurs une solution moderne pour partager leurs informations de contact de manière élégante et professionnelle.`,
    },
    {
      title: "Projet Ifat",
      imgPath: ifat,
      ghLink: "https://github.com/maha22i/projet-ifat",
      demoLink: "https://projet-ifat.onrender.com/",
      descriptionShort: "Ifat est une plateforme innovante de gestion pour les entreprises...",
      descriptionLong: `Ifat est  une plateforme innovante de gestion pour les entreprises, c'est un projet en cours de développement, visant à devenir une plateforme innovante de gestion destinée aux entreprises à Djibouti. Cette solution permettra de suivre les stocks en temps réel, de passer des commandes, et de gérer efficacement les ventes. L'objectif principal est de moderniser les processus commerciaux locaux en offrant une interface intuitive et des outils performants, adaptés aux besoins spécifiques des entreprises djiboutiennes. Ifat ambitionne de simplifier la gestion quotidienne tout en augmentant la productivité et la rentabilité.`,
    },
    {
      title: "Projet Idriss",
      imgPath: bitsOfCode,
      ghLink: "https://github.com/maha22i/projet-idriss",
      demoLink: "https://projet-idriss.onrender.com",
      descriptionShort: "Site internet statique réalisé avec HTML, CSS, et JavaScript...",
      descriptionLong: `Site internet statique réalisé avec HTML, CSS, et un peu de JavaScript, conçu pour répondre aux besoins d’un département de Djibouti Telecom. Ce projet a été développé pour permettre aux employés d’accéder directement en ligne à des documents spécifiques, sans avoir à contacter le service concerné. Avec une interface claire et intuitive, ce site offre une solution pratique et efficace pour centraliser et partager les ressources internes, tout en simplifiant le flux de communication au sein de l’équipe. Technologies utilisées : HTML, CSS, JavaScript.`,
    },
    {
      title: "Snake Game",
      imgPath: editor,
      ghLink: "https://github.com/maha22i/snakegame",
      demoLink: "https://snakegame-qyi9.onrender.com/",
      descriptionShort: "Un jeu classique réalisé en HTML, CSS et JavaScript...",
      descriptionLong: `Un jeu classique réalisé en HTML, CSS et JavaScript pour m'entraîner et améliorer mes compétences en développement web. Ce jeu classique met en pratique des concepts clés tels que la manipulation du DOM, les événements utilisateur et la gestion des animations. Le développement de ce projet m’a permis de renforcer ma maîtrise des bases de JavaScript tout en explorant des aspects comme la logique de jeu et l’interactivité dynamique.`,
    },
    {
      title: "Portfolio V1",
      imgPath: leaf,
      ghLink: "https://github.com/maha22i/chehemcv",
      demoLink: "https://chehemcv.onrender.com",
      descriptionShort: "La première version de mon portfolio personnel...",
      descriptionLong: `La première version de mon portfolio personnel, développée avec React.js pour présenter mon parcours, mes compétences et mes projets dans un format professionnel et accessible. Ce portfolio m’a permis de perfectionner mes compétences en React, notamment dans la création de composants dynamiques, la gestion de l’état, et la mise en œuvre d’une architecture modulaire.`,
    },
    {
      title: "Project Vélo",
      imgPath: suicide,
      ghLink: "https://github.com/maha22i/ProjectVelo",
      demoLink: "https://projectvelo.onrender.com",
      descriptionShort: "Un projet académique pour répertorier les stations de vélos à Toulouse...",
      descriptionLong: `Un projet académique réalisé en JavaScript pour répertorier toutes les stations de vélos à Toulouse. L’application affiche des informations en temps réel sur la disponibilité des vélos (ou emplacements libres) et permet également de réserver un vélo.`,
    },
    {
      title: "TP Traitement d'Image avec OpenCV",
      imgPath: emotion,
      ghLink: "https://github.com/maha22i/TP-TRAITEMENT-IMAGE",
      demoLink: null,
      descriptionShort: "Projet en Python utilisant OpenCV pour des tâches de traitement d'images...",
      descriptionLong: `Ce projet en Python utilise OpenCV et des modèles pré-entraînés pour effectuer des tâches de traitement d'images et de détection.Le projet inclut des techniques de détection d'objets telles que les cascades de Haar et les réseaux neuronaux convolutifs (CNN). L’objectif principal était de traiter et analyser des images à l’aide de méthodes avancées de vision par ordinateur, tout en générant des résultats visuels et en les expliquant dans un rapport détaillé. Ce projet m'a permis de me familiariser avec OpenCV, le traitement d'images et l’utilisation de modèles pour des tâches spécifiques de détection.`,
    },
    {
      title: "Application de méteo",
      imgPath: meteo,
      ghLink: "https://github.com/maha22i/MeteoAppFlutter",
      demoLink: null,
      descriptionShort: "Cette application météo, développée avec Flutter",
      descriptionLong: `Cette application météo, développée avec Flutter, offre une expérience utilisateur fluide et moderne pour consulter les conditions météorologiques en temps réel. Grâce à l'intégration de l'API OpenWeatherMap, les utilisateurs peuvent obtenir des informations précises sur la météo actuelle, les prévisions à court terme, ainsi que des détails tels que la température, l'humidité, et la vitesse du vent.l'application utilise Firebase pour l'authentification et la gestion des utilisateurs. Chaque utilisateur peut créer un compte, se connecter de manière sécurisée et sauvegarder ses localisations préférées dans une base de données Firestore.`,
    },
  ];

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Projet <strong className="purple">réalisé</strong>
        </h1>
        <p style={{ color: "white" }}>
          Voici quelques projets sur lesquels j'ai travaillé.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projects.map((project, index) => (
            <Col md={4} className="project-card" key={index}>
              <ProjectCard
                imgPath={project.imgPath}
                isBlog={false}
                title={project.title}
                description={
                  showMore[project.title]
                    ? project.descriptionLong
                    : project.descriptionShort
                }
                ghLink={project.ghLink}
                demoLink={project.demoLink}
                extraButton={
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => toggleDescription(project.title)}
                    style={{ marginLeft: "10px" }}
                  >
                    {showMore[project.title] ? "Voir moins" : "Voir plus"}
                  </Button>
                }
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
